import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, OverlayTrigger, Popover } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logoutProcess } from "../../../utils/Authentication/AppLogout";
import { SignOut } from "../../../actions/Dashboard";
import { getUserDataByKey } from "../../../utils/Authentication/handleUserSession";
import { routePath, routePrefix } from "../../../constants/Routes";
import profile_icon from "../../../assets/images/admin-portal/profile-icon.svg";
import customerUserPlaceholderIcon from "../../../assets/images/user-placeholder-icon.svg";
import "./EcoproModal.css";
import { UNIQUE_ID_GA } from "../../../constants/AppConstants";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const EcoproModal = ({ role, exactRole = null }) => {
  const { t } = useTranslation(["translation", "CSAgenttranslation"]);
  const dispatch = useDispatch();
  const currentURL = useLocation();
  const { accounts } = useMsal();
  const enrollmentStatus = useSelector(state => state.EnrollmentStatus);
  const MSALAccountName = (accounts.length > 0) ? accounts[0].name : null;

  /**
   * Method to check whether current page is admin or csagent page
   * to display text with proper translations
   * @returns boolean
   */
  const checkISAdminByPath = () => {
    return (currentURL?.pathname.indexOf(routePrefix.CSAGENT) > -1 || currentURL?.pathname.indexOf(routePrefix.ADMIN) > -1) ? true : false;
  }
  /**
   * Method to signout from the application
   */
  const handleLogout = () => {
    dispatch(SignOut());
    logoutProcess();
  };
  /**
   * Method to get display name of the logged in user
   * @returns string
   */
  const getUserDisplayName = () => {
    const userName = getUserDataByKey('displayName', 'personal_details');
    return (!userName) ? `${getUserDataByKey('firstName', 'personal_details')} ${getUserDataByKey('lastName', 'personal_details')}` : userName;
  }
  
  const displayName = getUserDisplayName().trim();
  const emailID = getUserDataByKey('emailAddress', 'personal_details');
  const [show, setShow] = useState(false);

  const onClose = (event) => {
    if (event.target.id !== "user-profile-backdrop") {
      setShow(false);
    }
  };

  let backdrop = document.getElementById("backdrop");
  backdrop && backdrop.addEventListener("click", onClose);

  const closeModal = () => {
    setShow(!show);
  };

  const customerHeader = (
    <Popover.Header as="h3">
        <div className="container">
          <div className="row">
            <div className="col-3 popover-img-wrapper">
              <div className="popover-img">
                <img src={customerUserPlaceholderIcon} alt="user-profile-icon"/>
              </div>
            </div>
            <div className="col-8 popover-user">
              { displayName && <div className="f-16 popover-name">{t("DASHBOARD.USER_PROFILE.HELLO")}, {(displayName) ? displayName : MSALAccountName}</div>}
              { emailID && <div className="f-12 popover-mail">
                {emailID}
              </div> }
            </div>
          </div>
        </div>
      </Popover.Header>
  )

  const agentHeader = (
    <Popover.Header as="h3">
      <div className="container">
        <div className="row">
          <div className="col popover-user">
             <div className="f-16 popover-name">
                  {
                    checkISAdminByPath() 
                    ? `${t('CSAgenttranslation:CS_AGENT_HEADER_POPUP.USER_PROFILE.HELLO')} ${displayName || MSALAccountName}` 
                    : `${t("DASHBOARD.USER_PROFILE.HELLO")} ${displayName || MSALAccountName}`
                  }
              </div>
            {emailID && <div className="f-12 popover-mail">{emailID}</div>}
          </div>
        </div>
      </div>
    </Popover.Header>
  );
  const popoverFooter = (
    <div className="user-options ">
      <div
        className="sign-out f-14 pointer-cursor ta-left fw-b"
        onClick={() => handleLogout()}
        id={UNIQUE_ID_GA.customerLogout}
      >
        { (checkISAdminByPath()) ? t("CSAgenttranslation:CS_AGENT_SIGN_OUT") : t("DASHBOARD.USER_PROFILE.SIGN_OUT")}
      </div>
    </div>
  );
  const popoverBody = (
    <Popover.Body className="f-14 fc-g ta-left fw-b">
      <Nav>
      <Container className={`${(!enrollmentStatus.enrollment_completed) ? 'navigation-disabled' : 'modal-links-activated'}`}>
          <div>
            <NavLink
              to={routePath.MY_ACCOUNT}
              activeClassName="active"
              className="fc-g"
              onClick={() => closeModal()}
              id={UNIQUE_ID_GA.myAccount}
            >
              {t("DASHBOARD.USER_PROFILE.ACCOUNT")}
            </NavLink>
          </div>

          {/* @todo Will be enabled after integration 
              <div>
                <span>{t("DASHBOARD.USER_PROFILE.SETTINGS")}</span>
              </div> */}
          <div>
            <NavLink
              to={routePath.HELP_SUPPORT}
              className="fc-g"
              activeClassName="active"
              onClick={() => closeModal()}
              id={UNIQUE_ID_GA.helpAndSupport}
            >
              {t("DASHBOARD.USER_PROFILE.HELP_SUPPORT")}
            </NavLink>
          </div>
        </Container>
      </Nav>
    </Popover.Body>
  );

  const customerPopover = (
    <Popover id="popover-positioned-bottom" className="customer-popover">
      {customerHeader}
      <div className="user-options ">
        {popoverBody}
        {popoverFooter}
      </div>
    </Popover>
  );
  const csAgentPopover = (
    <Popover id="popover-positioned-bottom" className={`agent-popover ${exactRole === 'admin' ? 'admin-popover' : "not-admin-popover"}`}>
      {agentHeader}
      {popoverFooter}
    </Popover>
  );
  const csAgentProfile = (
    <div className="profile-icon">
      <img
        id="user-profile-backdrop"
        src={profile_icon}
        alt="profile"
        onClick={() => closeModal()}
      />
    </div>
  );
  const customerProfile = (
    <div
      className="user-profile"
      onClick={() => closeModal()}
      id="user-profile-backdrop"
    >
      <img src={customerUserPlaceholderIcon} alt="User Profile" id="user-profile"/>
    </div>
  );

  return (
    <>
      {/* @todo Will be enabled after integration
      <img src={notification_icon} alt="notification" className="bell-icon" /> */}
      <OverlayTrigger
        OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          role === "customer"
            ? customerPopover
            : role === "admin-csagent"
            ? csAgentPopover
            : null
        }
        rootClose
        rootCloseEvent="click"
        show={show}
      >
        {role === "customer"
          ? customerProfile
          : role === "admin-csagent"
          ? csAgentProfile
          : null}
      </OverlayTrigger>
    </>
  );
};

export default EcoproModal;
