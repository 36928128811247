import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormGroup, Input } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DisplayDate } from "../../utils/DisplayDate";
import creditCardIcon from "../../assets/images/creditcard-icon.svg";
import SupplyShippedIcon from "../../assets/images/supplied-shipped-icon.svg";
import "./DashboardOrdersHistory.css";
import { routePath } from "../../constants/Routes";
import { ORDER_TYPES, UNIQUE_ID_GA } from "../../constants/AppConstants";
import { getOrderTypeTranslation } from "../../constants/AppMethod";
import { getLangCode } from "../../utils/Internationalization/handleAppInternationalization";

const DashboardOrdersHistory = (props) => {
  let listLimit = 0;
  const { orderHistory, isDashboardHomepage = true, cname = null } = props;
  const { t } = useTranslation(["translation"]);
  const [filteredData, setFilteredData] = useState(orderHistory);
  const billingInfo = useSelector((state) => state.DashboardOrderHistory);
  const dateDisplayOptions = { month: "long" };
  const langCode = getLangCode()
  useEffect(() => {
    handleOrderHistoryData(orderHistory);
  }, [orderHistory]);

  listLimit = (isDashboardHomepage) ? 1 : Object.keys(filteredData).length;
  
  const handleOrderHistoryData = (orderHistory) => {
    // Merge arrays
    const invoiceRaisedHistory = orderHistory?.invoiceRaisedHistory;
    const supplyShippedHistory = orderHistory?.supplyShippedHistory;
    if(Object.keys(orderHistory).length > 0){
      const mergedArray = [...invoiceRaisedHistory, ...supplyShippedHistory];

      // Sort merged array by date
      mergedArray.sort((a, b) => new Date(b.displayDateFormat.replace("/", "-")) - new Date(a.displayDateFormat.replace("/", "-")));

      // Group array by month
      const groupedByMonth = mergedArray.reduce((acc, obj) => {
        const month = obj.displayDateFormat.replace("/", "-").slice(0, 7);
        if (!acc[month]) {
          acc[month] = [];
        }
        acc[month].push(obj);
        return acc;
      }, {});

      setFilteredData(groupedByMonth);
    } else {
      setFilteredData([]);
    }
  }
  const handleSearch = (event) => {
    let filteredResult;
    let searchResult = { orderHistory: [] };
    let val = event.target.value.toLowerCase();

    filteredResult = orderHistory.orderHistory.filter((data) => {
      const orderMonth = DisplayDate(data.date, dateDisplayOptions, langCode);
      const orderDate = new Date(data.date).getUTCFullYear().toString();
      return orderMonth.indexOf(val) > -1 || orderDate.indexOf(val) > -1
        ? data
        : "";
    });
    setFilteredData({ ...searchResult, orderHistory: filteredResult });
  };
  
  return (
    <div className = {`account-recent-orders-wrap ${(!isDashboardHomepage) ? "orderslist-pg" : "ordershome-pg"} ${cname}`}>
      {!isDashboardHomepage && <h1>{t('DASHBOARD.ORDERS.ORDER_HISTORY')}</h1>}

      {!isDashboardHomepage && (
        <FormGroup className="order-search d-none">
          <Input
            id="orders-search"
            name="search"
            placeholder="Search for an order..."
            type="search"
            onKeyUp={(event) => handleSearch(event)}
          />
          <i className="search-icon"></i>
        </FormGroup>
      )}

      <div className="header">
        {isDashboardHomepage && <h4>{t("DASHBOARD.ORDERS.RECENT_ORDERS")}</h4>}
        {isDashboardHomepage &&
          Object.keys(filteredData).length > 0 && (
            <Link to={routePath.ORDERS} id={UNIQUE_ID_GA.orderHistory+"-view-all"}>{t("DASHBOARD.ORDERS.VIEW_ALL")}</Link>
          )}
      </div>

      <div className="order-list-wrapper">
        {billingInfo.loading ? (
          <>
            <div className="content">
              <div className="row">
                <div className="col-2">
                  <Skeleton className="square-skeleton" />
                </div>
                <div className="col-8 order-status-wrapper">
                  <Skeleton count={2} />
                </div>
              </div>
            </div>
            <div className="content mt-30">
              <div className="row">
                <div className="col-2">
                  <Skeleton className="square-skeleton" />
                </div>
                <div className="col-8 order-status-wrapper">
                  <Skeleton count={2} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
          {Object.keys(filteredData).length === 0 && (
                <p className="no-orders-txt">
                  {t("DASHBOARD.ORDERS.NO_RECENT_ORDERS")}
                </p>
              )}
            {filteredData.hasOwnProperty("orderHistory") &&
              filteredData.orderHistory.length === 0 && (
                <p className="no-orders-txt">
                  {t("DASHBOARD.ORDERS.NO_RECENT_ORDERS")}
                </p>
              )}

            {         
              Object.entries(filteredData).slice(0, listLimit).map(([date, orderlistArr]) => (
                  <div className="content" key={date}>
                      {!isDashboardHomepage && (
                        <h4>
                          { DisplayDate(date, dateDisplayOptions, langCode) }{" "}
                          {new Date(date).getUTCFullYear()}
                        </h4>
                      )}
                        {
                        orderlistArr.length > 0 && Object.values(orderlistArr).slice(0, (isDashboardHomepage) ? 3 : orderlistArr.length).map((orderlist, index) => (
                        <div className="content-list-wrapper">
                          <div className="order-list">
                            <div className="order-icon">
                              {orderlist.orderType === ORDER_TYPES.SUPPLIES_SHIPPED && <img src={SupplyShippedIcon} alt="creditcard-icon" />}
                              {orderlist.orderType !== ORDER_TYPES.SUPPLIES_SHIPPED && <img src={creditCardIcon} alt="creditcard-icon" />}
                            </div>
                            <div className="order-status-wrapper">
                              <h5>{getOrderTypeTranslation(orderlist.orderType)}</h5>
                              <p>
                                {t("DASHBOARD.ORDERS.SUBSCRIPTION_MONTH", {
                                  subscription_month: DisplayDate(orderlist.date, dateDisplayOptions, langCode),
                                })}
                              </p>
                            </div>
                          </div>
                          <div className="order-detail-btn">
                            { 
                            (orderlist.orderType === ORDER_TYPES.SUPPLIES_SHIPPED) 
                            ?  <Link to={{
                                  pathname: `${routePath.ORDER}/${orderlist.orderId}`,
                                  state: {
                                    orderDetails: orderlist,
                                  },
                                }}
                                id={UNIQUE_ID_GA.orderHistory+"-"+orderlist.invoiceId}>
                                {t("DASHBOARD.ORDERS.DETAILS_BTN")}
                              </Link>  
                            : <Link to={{
                                    pathname: `${routePath.ORDER}/${orderlist.planId}`,
                                    state: {
                                      orderDetails: orderlist,
                                    },
                                  }}
                                  id={UNIQUE_ID_GA.orderHistory+"-"+orderlist.invoiceId}
                                >
                                {t("DASHBOARD.ORDERS.DETAILS_BTN")}
                              </Link> 
                            }
                          </div>
                        </div>
                        ))}
                    </div>
                ))
            }
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardOrdersHistory;
