import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { WebCustomerRoutePaths } from "../../../../constants/Routes";
import Loader from "../../../shared/loader/Loader";
import './ListPrinterModel.css';
import { setLocalSessionData } from "../../../../utils/Authentication/handleUserSession";
import { LOCALSESSIONDATAKEYS, SIGNUPTYPE } from "../../../../constants/AppConstants";
import { getKeyByValue, Is_BrotherPlus_Signup, pushDataLayer } from "../../../../constants/AppMethod";
import { printerTypes } from "../../../../constants/PrinterTypes";
const ListPrinterModel = (props) => {
    const { t } = useTranslation(["translation"]);
    const history = useHistory();
    const { modelsData, selectedModel, printerType } = props;
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    if(modelsData?.models?.length > 0 && modelsData?.models?.length % 3 > 0){
        const reminder = modelsData.models.length % 3;
        (reminder === 2) ? modelsData.models.push([]) : modelsData.models.push([], []);
    }
    const goToPlansPage = (printerModel) => {
      pushDataLayer('select_model','model',printerModel)
      setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL, printerModel);
      const redirectInfo = (IsBrotherPlus_Signup)
      ? {pathname: WebCustomerRoutePaths.WEB_REDIRECT, state:{'signupType': SIGNUPTYPE.BROTHER_PLUS}}
      : {pathname: WebCustomerRoutePaths.SELECT_PLAN, state: { printer_model: printerModel }};
      
      history.push(redirectInfo);
    }
    if(modelsData?.loading){
        return <Loader></Loader>
    }

    let translatedPrinterType;
    if(printerTypes && printerType){
      const translationPrinterKey  = getKeyByValue(printerTypes, printerType);
      translatedPrinterType = t(`CUSTOMER_WEB.SELECT_PRINTER.${translationPrinterKey}`)
    }

    return(
        <div className="select-printer-models-wrapper mt-40">
            <h5 className="side-heading">{t('CUSTOMER_WEB.SELECT_PRINTER.CHOOSE_SERIES', {model:translatedPrinterType})}</h5>
            {
              modelsData?.models?.length === 0  && <p className="no-results-found">{t("CUSTOMER_WEB.ERROR_CONTENT.NO_RESULTS_FOUND")}</p>
            }
            {
              modelsData?.error  && <p className="error no-results-found">{modelsData.error}</p>
            }
            <div className="printer-model-list-wrapper">
                {
                  modelsData?.models?.length > 0 && modelsData?.models?.map((val) => (
                    <div className={`${(val.model) ? "item" : "invisible-item"} ${selectedModel === val.model ? "pre-selected" : ""}`} key={val.id} id={`select_model_${val.model}`} onClick={() => goToPlansPage(val.model)}>
                        { val.model && <img src={val.deviceImageUrl} alt="printer"/> }
                        <p>{val.displayName}</p>
                    </div>      
                  ))  
                }
            </div>
        </div>
    );
}

export default ListPrinterModel;